import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HrefDirective } from "@core/app/href.directive";
import { HtmlToPlaintextPipe } from "@core/app/html-to-plaintext.pipe";
import { TelPipe } from "@core/app/tel.pipe";
import { Image2Component } from "./components/image2/image2.component";
import { ImagePipe } from "@common/pipes/image";

@NgModule({
	declarations: [HrefDirective, HtmlToPlaintextPipe, TelPipe, Image2Component, ImagePipe],
	imports: [CommonModule],
	exports: [HrefDirective, HtmlToPlaintextPipe, TelPipe, Image2Component, ImagePipe],
})
export class ImageModule {}
