import { Component, HostBinding, Input, ElementRef, ViewChild, Output, EventEmitter } from "@angular/core";

let nextIdNum = 0;

@Component({
	selector: "cm-field-text",
	templateUrl: "./field-text.component.html",
	styles: [":host { display: block; }"],
})
export class FieldTextComponent {
	@Input() type: string = "text";
	@Input() name!: string;
	@Input() placeholder: string = "";
	@Input() autocomplete?: string;
	@Input() disabled: boolean = false;
	@Input() hideCount: boolean = false;
	@Input() label?: string;
	@Input() intro?: string;
	@Input() maxLen?: number;
	@Input() minLen?: number;
	@Input() required: boolean = false;
	@Input() pattern?: string | null;
	@Input() value: string | null = null;

	@Output() valueChange = new EventEmitter<string | null>();

	@HostBinding("class.was-validated")
	get hostClassHasSuccess(): boolean {
		return this.validated;
	}

	@ViewChild("input", { static: true }) input!: ElementRef<HTMLInputElement>;

	idNum: number = 0;
	error: string | null = null;
	focused: boolean = false;
	validated: boolean = false;

	constructor() {
		this.idNum = nextIdNum++;
	}

	get id(): string {
		return `field-text-${this.idNum}`;
	}

	get inputStyle(): { [key: string]: string } | null {
		return this.maxLen ? { "max-width": `${this.maxLen * 1.1}ch` } : null;
	}

	onModelChange() {
		if (this.value === "") {
			this.value = null;
		}

		this.valueChange.emit(this.value);
	}

	validate() {
		if (!this.input.nativeElement.checkValidity()) {
			this.error = this.input.nativeElement.validationMessage;
		} else {
			this.error = null;
		}

		this.validated = true;
	}
}
