import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbTypeaheadModule } from "@ng-bootstrap/ng-bootstrap";
import { SearchComponent } from "./components/search/search.component";

@NgModule({
	declarations: [SearchComponent],
	imports: [CommonModule, FontAwesomeModule, FormsModule, NgbTypeaheadModule, RouterModule],
	exports: [SearchComponent],
})
export class SearchModule {}
