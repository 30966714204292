import { NgModule } from "@angular/core";
import { FacebookSlideshowComponent } from "./components/facebook-slideshow/facebook-slideshow.component";
import { SliderModule } from "../slider/slider.module";
import { CommonModule } from "@angular/common";

@NgModule({
	declarations: [FacebookSlideshowComponent],
	imports: [CommonModule, SliderModule],
	exports: [FacebookSlideshowComponent],
})
export class FacebookModule {}
