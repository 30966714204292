import { Component, OnInit, Inject, Input } from "@angular/core";
import {
	faFacebook,
	faPinterest,
	faTwitter,
	faInstagram,
	faYoutube,
	faGoogle,
	faTumblr,
} from "@fortawesome/free-brands-svg-icons";
import { IPageData } from "@model/page-data";
import { ISocialLink } from "@model/social-link";

@Component({
	selector: "cm-social-links2",
	template: `
		<a
			*ngFor="let socialLink of socialLinks"
			href="{{ socialLink.link }}"
			target="_blank"
			itemprop="sameAs"
			title="{{ socialLink.desc }}"
			rel="noopener"
			class="social-link mx-1"
		>
			<fa-icon class="social-icon" [icon]="socialLink.icon" [size]="iconSize"></fa-icon>
		</a>
	`,
	styles: [],
})
export class SocialLinks2Component implements OnInit {
	socialLinks: ISocialLink[] = [];

	@Input() iconSize: string = "2x";

	constructor(@Inject("PAGE_DATA") private pageData: IPageData) {}

	ngOnInit() {
		for (const item of this.pageData.headerData.queries.GetDealerSocialAffiliateLinks) {
			const current: ISocialLink = {
				id: item.img,
				src: item.img_dir + item.img_file,
				desc: item.affiliate_name,
				link: item.site_affiliate_url,
			};
			switch (current.desc) {
				case "Facebook":
					current.icon = faFacebook;
					break;
				case "Pinterest":
					current.icon = faPinterest;
					break;
				case "Twitter":
					current.icon = faTwitter;
					break;
				case "Tumblr":
					current.icon = faTumblr;
					break;
				case "Instagram":
					current.icon = faInstagram;
					break;
				case "Youtube":
				case "YouTube":
					current.icon = faYoutube;
					break;
				case "Google":
				case "Google +":
					current.icon = faGoogle;
					break;
			}
			this.socialLinks.push(current);
		}
	}
}
