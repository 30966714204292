import { Component, Input } from "@angular/core";

@Component({
	selector: "cm-image2",
	template: `
		<ng-container *ngIf="image">
			<ng-container *ngIf="image.disableLazyLoad; else lazyLoad">
				<img
					class="img-fluid {{ image.imgClass }}"
					[src]="image.src"
					[alt]="image.alt"
					[title]="image.title || image.alt"
				/>
			</ng-container>
		</ng-container>
		<ng-template #lazyLoad>
			<img
				loading="lazy"
				[alt]="image.alt"
				[title]="image.title || image.alt"
				class="img-fluid lazyload {{ image.imgClass }}"
				[src]="image.src"
			/>
		</ng-template>
	`,
	styles: [
		`
			:host {
				display: block;
			}
			img {
				object-fit: cover;
				object-position: 50% 50%;
				margin: auto;
			}
			img.ng-lazyloaded {
				animation: fadein 0.5s;
			}
			@keyframes fadein {
				from {
					opacity: 0;
				}
				to {
					opacity: 1;
				}
			}
		`,
	],
})
export class Image2Component {
	@Input() image: any;
	defaultImage = "https://dfm-cdn.com/dms/static-imgs/1x1-ffffff7f.png";
}
