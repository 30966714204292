import { Component, HostBinding, Input } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Thing } from "schema-dts";

@Component({
	selector: "cm-json-ld",
	template: "",
})
export class JsonLdComponent {
	@Input()
	set json(val: Thing | undefined | null) {
		const json = val ? JSON.stringify(val).replace(/<\/script>/g, "<\\/script>") : "";
		this.innerHTML = this.sanitizer.bypassSecurityTrustHtml(`<script type="application/ld+json">${json}</script>`);
	}

	@HostBinding("innerHTML") innerHTML?: SafeHtml;

	constructor(private sanitizer: DomSanitizer) {}
}
