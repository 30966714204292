import { Injectable } from "@angular/core";
import Cookies from "js-cookie";

@Injectable({
	providedIn: "root",
})
export class CookieService {
	cookies: any = {};

	clear() {
		for (const key of Object.keys(this.cookies)) {
			this.remove(key);
		}
	}

	get(key: any) {
		return this.has(key) ? Cookies.get(key) : null;
	}

	has(key: any) {
		return Cookies && Cookies.get(key) !== undefined;
	}

	remove(key: any) {
		if (Cookies) {
			return Cookies.remove(key);
		}
	}

	set(key: any, value: any, expires?: any) {
		if (expires) {
			Cookies.set(key, value, { expires: expires });
		} else {
			Cookies.set(key, value);
		}
	}
}
