import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { FooterAthomeComponent } from "./components/footer-athome/footer-athome.component";
import { LocationsComponent } from "./components/locations/locations.component";
import { TrustIconsComponent } from "./components/trust-icons/trust-icons.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { AccountModule } from "../account/account.module";

@NgModule({
	declarations: [LocationsComponent, FooterAthomeComponent, TrustIconsComponent],
	imports: [CommonModule, SharedModule, FontAwesomeModule, AccountModule],
	exports: [FooterAthomeComponent],
})
export class FooterModule {}
