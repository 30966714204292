import { Component, Inject, Input } from "@angular/core";
import { GTMService } from "@core/app/gtm.service";
import { IPageData } from "@model/page-data";
import { IGetLocations } from "@model/stmt/GetLocations";

@Component({
	selector: "cm-locations",
	template: `
		<a class="btn btn-primary mb-3" routerLink="/our-offices">{{ header }}</a>
		<ul class="list-inline d-flex" style="gap: 1rem">
			<li *ngFor="let location of locations" itemscope itemtype="http://schema.org/Organization" class="pr-3">
				<meta itemprop="name" [content]="location.location || location.city" />
				<div class="mb-2 name">
					<strong
						><a itemprop="url" routerLink="{{ location.content_page_url }}">{{
							location.location || location.city
						}}</a></strong
					>
				</div>
				<span itemprop="address" itemscope itemtype="http://schema.org/PostalAddress">
					<div class="address" itemprop="streetAddress">{{ location.address1 }} {{ location.address2 }}</div>
					<div class="cityStateZip mb-2">
						<span itemprop="addressLocality">{{ location.city }}</span
						>&nbsp; <span itemprop="addressRegion">{{ location.abbrev }}</span
						>&nbsp;
						<span itemprop="postalCode">{{ location.postal_code }}</span>
					</div>
				</span>
				<div
					class="phone"
					*ngIf="location.org_phone_number"
					itemprop="contactPoint"
					itemtype="http://schema.org/ContactPoint"
					itemscope
				>
					<meta itemprop="areaServed" content="US" />
					<meta itemprop="availableLanguage" content="English" />
					<meta itemprop="telephone" content="+1-{{ location.org_phone_number }}" />
					<meta itemprop="contactType" content="sales" />
					<a
						(click)="linkClicked('Link Click', 'Phone Call', 'click')"
						itemprop="url"
						rel="nofollow"
						href="tel:{{ location.org_phone_number }}"
						>{{ location.org_phone_number }}</a
					>
				</div>
				<div class="link" *ngIf="location.external_website_url">
					<a itemprop="url" target="_blank" [href]="location.external_website_url">Visit Website ></a>
				</div>
			</li>
		</ul>
	`,
	styles: [
		`
			:host {
				padding-bottom: 1rem;
			}
			ul {
				margin-bottom: 0rem;
			}
			li:not(:last-child) {
				border-right: 1px solid;
			}
			.list-inline a {
				color: #80c03e;
			}
			@media (max-width: 575px) {
				ul,
				h4 {
					display: none;
				}
			}
		`,
	],
})
export class LocationsComponent {
	@Input() header: string = "Our Offices";
	@Input() link: string = "/our-offices";
	locations: IGetLocations[] = this.pageData.locations;
	constructor(@Inject("PAGE_DATA") private pageData: IPageData, private gtmService: GTMService) {}
	linkClicked(category: string, label: string, action: string) {
		this.gtmService.track(category, action, label);
	}
}
