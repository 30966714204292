import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
	selector: "cm-login-modal",
	template: `
		<cm-modal [show]="show" (showChange)="showChange.next($event)">
			<ng-template cmModal>
				<div class="container-fluid">
					<div class="row">
						<h4 class="h3 m-0 col-12 bg-primary text-center text-white text-uppercase flex-center p-3">
							Log In
						</h4>
						<div class="col-12 p-sm-5 p-3 flex-center">
							<cm-account
								(loggedIn)="loggedIn.next(); showChange.next(false)"
								[state]="state"
							></cm-account>
						</div>
					</div>
				</div>
			</ng-template>
		</cm-modal>
	`,

	styles: [
		`
			:host ::ng-deep .modal-inner > .container {
				padding-left: 0;
				padding-right: 0;
			}
			:host ::ng-deep .close-btn {
				color: #000;
			}
		`,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginModalComponent {
	@Input() show!: boolean;
	@Input() state = LoginState.Login;
	@Output() loggedIn = new EventEmitter<void>();
	@Output() showChange = new EventEmitter<boolean>();
	LoginState = LoginState;
}

enum LoginState {
	Login,
	Register,
	ForgotPass,
}
