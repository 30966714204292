// dependencies
import { HttpClientModule } from "@angular/common/http";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { SharedModule } from "@core/app/shared/shared.module";
import { Ng2UiAuthModule, StorageType } from "ng2-ui-auth";
import { AccountModule } from "./account/account.module";
import { EqualValidatorDirective } from "./equal-validator.directive";
import { FacebookModule } from "./facebook/facebook.module";
import { FooterModule } from "./footer/footer.module";
import { HeaderAtHomeComponent } from "./header-meta/header-athome/header-athome.component";
import { ImageModule } from "./image/image.module";
import { InitDirective } from "./init.directive";
import { InstagramModule } from "./instagram/instagram.module";
import { InventoryModule } from "./inventory/inventory.module";
import { Menu2Component } from "./menu2/menu2.component";
import { RouteResolver } from "./route-resolver.service";
import { SliderModule } from "./slider/slider.module";
import { config } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

const auth = {
	storageType: StorageType.LOCAL_STORAGE,
};
auth.storageType = StorageType.MEMORY;

@NgModule({
	declarations: [EqualValidatorDirective, InitDirective, Menu2Component, HeaderAtHomeComponent],
	imports: [
		BrowserAnimationsModule,
		BrowserModule,
		HttpClientModule,
		Ng2UiAuthModule.forRoot(auth),
		RouterModule.forChild([]),
		SharedModule,
		SliderModule,
		FooterModule,
		InstagramModule,
		FacebookModule,
		InventoryModule,
		ImageModule,
		AccountModule,
		FontAwesomeModule
	],
	exports: [
		HeaderAtHomeComponent,
		EqualValidatorDirective,
		InitDirective,
		SharedModule,
		SliderModule,
		FooterModule,
		InstagramModule,
		FacebookModule,
		InventoryModule,
		ImageModule,
		AccountModule,
	],
})
export class CoreModule {
	static forRoot(): ModuleWithProviders<CoreModule> {
		return { ngModule: CoreModule, providers: [RouteResolver] };
	}
	constructor() {
		config.autoAddCss = false;
	}
}
