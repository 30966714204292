import { Inject, Injectable } from "@angular/core";
import { ConfigService } from "./config.service";

/**
 * The Cache Service returned for the specified type
 *
 * @param type
 * @returns {{storage: null, init: init, get: get, has: has, set: set}}
 * @constructor
 */
export class Service {
	storage: any;

	constructor(private config: ConfigService, type: string) {
		this.storage = (window as any)[type || "sessionStorage"];
	}

	/**
	 * Clears everything in the storage
	 */
	clear() {
		if (this.storage !== null) {
			this.storage.clear();
		}
	}

	/**
	 * Retrieves the object by key name
	 *
	 * @param key
	 * @returns {*}
	 */
	get(key: any) {
		if (this.storage !== null && this.storage.hasOwnProperty(key)) {
			return JSON.parse(this.storage[key]);
		} else {
			return null;
		}
	}

	/**
	 * Checks to see if the key exists in the cache
	 *
	 * @param key
	 */
	has(key: any) {
		if (this.storage !== null) {
			return this.storage.hasOwnProperty(key);
		}
	}

	/**
	 * Removes an item from the cache
	 *
	 * @param key
	 */
	remove(key: any) {
		if (this.storage !== null && this.has(key)) {
			this.storage.removeItem(key);
		}
	}

	/**
	 * Sets the key in the cache to the val specified
	 *
	 * @param key
	 * @param val
	 */
	set(key: any, val: any) {
		if (this.storage !== null && this.config.cachingEnabled) {
			try {
				this.storage.setItem(key, JSON.stringify(val));
			} catch (e) {
				console.error("Storage Quota exceeded!", e);
			}
		}
	}
}

@Injectable({ providedIn: "root" })
export class CacheService {
	constructor(@Inject(ConfigService) private config: ConfigService) {}

	init(type: string): Service {
		return new Service(this.config, type);
	}
}
