import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes, UrlSegment, UrlMatchResult, UrlMatcher } from '@angular/router';
import { CoreModule } from '../core.module';

import { RouteResolver } from "@core/app/route-resolver.service";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@core/environments/environment';
import { RootComponent } from "@core/app/pages/root/root.component";
import { PAGE_DATA } from "./page-data";
import { ToastNoAnimationModule } from 'ngx-toastr';
    import { PrerenderModule } from "shared";

export const routes: Routes = [
    {
        matcher: routeMatcher0 as UrlMatcher,
        loadChildren: () => import("./pages/page-admin/page-admin.module").then((m) => m.PageAdminModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Admin",
            pageTemplateId: 88,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/new-admin).*$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher1 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Blog",
            pageTemplateId: 37,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/(blog))(?:\\/([0-9]+))?(?:\\?.*)?$",
            rewriteTo: "content_page_url=1&blog=2&page=3",
            stmtid: 93,
        }
    },
    {
        matcher: routeMatcher2 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "BlogPost",
            pageTemplateId: 14,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^\\/blog\\/([^\\/]+)(?:\\/([0-9]+))?(?:\\?.*)?$",
            rewriteTo: "blog_post=1&page=2",
            stmtid: 93,
        }
    },
    {
        matcher: routeMatcher3 as UrlMatcher,
        loadChildren: () => import("./pages/page-account/page-account.module").then((m) => m.PageAccountModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Account",
            pageTemplateId: 60,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/my-account)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher4 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "About",
            pageTemplateId: 61,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/about-us)$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher5 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 115,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/meet-the-team)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher6 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 116,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/what-makes-us-different)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher7 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 117,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/connect-with-us)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher8 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 118,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/giving-back)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher9 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 111,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/open-houses)(?:\\/([0-9]+))?(?:\\?.*)?$",
            rewriteTo: "content_page_url=1&page=2&highlightid=Open Homes",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher10 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 110,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/cities)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher11 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 104,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/contact)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher12 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 103,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/careers)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher13 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 101,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/dream-home)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher14 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 100,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/advantage)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher15 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 98,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/buyers-sellers)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher16 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 90,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/listings\\/.+\\/.+\\/.+\\/([0-9]+))(?:\\?.*)?$",
            rewriteTo: "content_page_url=1&slug=2",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher17 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 97,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/our-offices\\/.+\\/.+)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher18 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 95,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/our-offices)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher19 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 94,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/listings)(?:\\/([0-9]+))?(?:\\?.*)?$",
            rewriteTo: "content_page_url=1&page=2",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher20 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 91,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/our-offices\\/.+)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher21 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Home",
            pageTemplateId: 39,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/)([0-9]+)?(?:\\?.*)?$",
            rewriteTo: "content_page_url=1&page=2",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher22 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 112,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/home-estimate)$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher23 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 113,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/find-my-dream-home)$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher24 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 104,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/contact-us)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher25 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 168,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/listings\\/.+?\\/.+?)(?:\\/([0-9]+))?(?:\\?.*)?$",
            rewriteTo: "content_page_url=1&page=2",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher26 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 175,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/listings\\/.+?)(?:\\/([0-9]+))?(?:\\?.*)?$",
            rewriteTo: "content_page_url=1&page=2",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher27 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 489,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/property-type\\/.+?)(?:\\/([0-9]+))?(?:\\?.*)?$",
            rewriteTo: "content_page_url=1&page=2",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher28 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 13,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/.+?)(?:(\\/[0-9]+))?(?:\\?.*)?$",
            rewriteTo: "content_page_url=1&page=2",
            stmtid: 85,
        }
    },
];

export function routeMatcher0(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/new-admin).*$/, segments, true); }
export function routeMatcher1(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/(blog))(?:\/([0-9]+))?(?:\?.*)?$/, segments, false); }
export function routeMatcher2(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^\/blog\/([^\/]+)(?:\/([0-9]+))?(?:\?.*)?$/, segments, false); }
export function routeMatcher3(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/my-account)(?:\?.*)?$/, segments, false); }
export function routeMatcher4(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/about-us)$/, segments, false); }
export function routeMatcher5(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/meet-the-team)(?:\?.*)?$/, segments, false); }
export function routeMatcher6(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/what-makes-us-different)(?:\?.*)?$/, segments, false); }
export function routeMatcher7(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/connect-with-us)(?:\?.*)?$/, segments, false); }
export function routeMatcher8(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/giving-back)(?:\?.*)?$/, segments, false); }
export function routeMatcher9(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/open-houses)(?:\/([0-9]+))?(?:\?.*)?$/, segments, false); }
export function routeMatcher10(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/cities)(?:\?.*)?$/, segments, false); }
export function routeMatcher11(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/contact)(?:\?.*)?$/, segments, false); }
export function routeMatcher12(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/careers)(?:\?.*)?$/, segments, false); }
export function routeMatcher13(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/dream-home)(?:\?.*)?$/, segments, false); }
export function routeMatcher14(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/advantage)(?:\?.*)?$/, segments, false); }
export function routeMatcher15(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/buyers-sellers)(?:\?.*)?$/, segments, false); }
export function routeMatcher16(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/listings\/.+\/.+\/.+\/([0-9]+))(?:\?.*)?$/, segments, false); }
export function routeMatcher17(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/our-offices\/.+\/.+)(?:\?.*)?$/, segments, false); }
export function routeMatcher18(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/our-offices)(?:\?.*)?$/, segments, false); }
export function routeMatcher19(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/listings)(?:\/([0-9]+))?(?:\?.*)?$/, segments, false); }
export function routeMatcher20(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/our-offices\/.+)(?:\?.*)?$/, segments, false); }
export function routeMatcher21(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/)([0-9]+)?(?:\?.*)?$/, segments, false); }
export function routeMatcher22(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/home-estimate)$/, segments, false); }
export function routeMatcher23(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/find-my-dream-home)$/, segments, false); }
export function routeMatcher24(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/contact-us)(?:\?.*)?$/, segments, false); }
export function routeMatcher25(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/listings\/.+?\/.+?)(?:\/([0-9]+))?(?:\?.*)?$/, segments, false); }
export function routeMatcher26(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/listings\/.+?)(?:\/([0-9]+))?(?:\?.*)?$/, segments, false); }
export function routeMatcher27(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/property-type\/.+?)(?:\/([0-9]+))?(?:\?.*)?$/, segments, false); }
export function routeMatcher28(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/.+?)(?:(\/[0-9]+))?(?:\?.*)?$/, segments, false); }

export function routeMatcher(regex: RegExp, segments: UrlSegment[], hasChildRouter: boolean): UrlMatchResult | null {
    if (regex.test("/" + segments.map(x => x.path).join("/"))) {
        if (hasChildRouter) {
            return { consumed: [segments[0]] };
        } else {
            return { consumed: segments };
        }
    } else {
        return null;
    }
}

@NgModule({
    declarations: [RootComponent],
    imports: [
        CommonModule,
        CoreModule.forRoot(),
        ToastNoAnimationModule.forRoot({ positionClass: 'toast-top-center' }),
        PrerenderModule,
        RouterModule.forRoot(routes, {
            initialNavigation: "enabledBlocking",
            scrollPositionRestoration: "enabled",
            anchorScrolling: "enabled",
            onSameUrlNavigation: "reload",
        }),
        ServiceWorkerModule.register("./ngsw-worker.js", { enabled: environment.production }),
        
    ],
    providers: [{ provide: "PAGE_DATA", useValue: PAGE_DATA }, ],
    entryComponents: [RootComponent],
    bootstrap: [RootComponent],
})
export class AppModule { }
