import { Component, ChangeDetectionStrategy, HostListener } from "@angular/core";
import { faSearch, faSmile } from "@fortawesome/pro-solid-svg-icons";
import { Router } from "@angular/router";

// TODO: generate this component at build time to get rid of the ngSwitch, so the unused templates can be treeshaken

@Component({
	selector: "cm-header-athome",
	template: `
		<header id="nav-header" class="header container-fluid">
			<div class="row align-items-center" itemscope itemtype="http://schema.org/WPHeader">
				<div class="d-none d-md-block col-md-4">
					<div class="search">
						<cm-search
							placeholder="Enter city, zip, county, or neighborhood"
							buttonClass="primary"
							[buttonIcon]="faSearch"
							buttonText=""
							(search)="search($event)"
						></cm-search>
					</div>
				</div>
				<div class="col-12 col-md-4 text-center">
					<a routerLink="/" class="d-block my-2 home-link">
						<span class="text-athome-green"><fa-icon [icon]="faSmile"></fa-icon><b>Home</b>Realty</span></a
					>
				</div>
				<div class="col-auto col-md-4 menu d-flex justify-content-end">
					<cm-menu2
						[appMenuid]="7"
						rootStyle="inline"
						subStyle="unstyled"
						itemscope
						itemtype="http://schema.org/SiteNavigationElement"
					></cm-menu2>
				</div>
			</div>
		</header>
	`,
	styleUrls: ["./header-athome.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderAtHomeComponent {
	faSearch = faSearch;
	faSmile = faSmile;
	constructor(private router: Router) {}
	search(text: string) {
		this.router.navigateByUrl(`/listings?keywords=${text}`);
	}
}
