import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { SmartCrumbsComponent } from "./components/smart-crumbs.component";

@NgModule({
	declarations: [SmartCrumbsComponent],
	imports: [CommonModule, FontAwesomeModule, RouterModule],
	exports: [SmartCrumbsComponent],
})
export class SmartCrumbsModule {}
