import { CommonModule, Location } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CampaignPickerComponent } from "@core/app/adwords/components/campaign-picker/campaign-picker.component";
import { ArrowDivComponent } from "@core/app/arrow-div/arrow-div.component";
import { AttrDirective } from "@core/app/attr.directive";
import { AutocompleteComponent } from "@core/app/autocomplete/autocomplete.component";
import { ButtonModule } from "@core/app/button/button.module";
import { ClickCopyDirective } from "@core/app/click-copy.directive";
import { FormTransparentComponent } from "@core/app/form-transparent/form-transparent.component";
import { GroupByPipe } from "@core/app/group-by.pipe";
import { ImageModule } from "@core/app/image/image.module";
import { InventoryModule } from "@core/app/inventory/inventory.module";
import { LayoutModule } from "@core/app/layout/layout.module";
import { LazyModule } from "@core/app/lazy/lazy.module";
import { Map2Module } from "@core/app/map2/map2.module";
import { Pagination2Module } from "@core/app/pagination2/pagination2.module";
import { SearchModule } from "@core/app/search/search.module";
import { SocialLinks2Component } from "@core/app/shared/components/social-links2/social-links2.component";
import { TableFiltersComponent } from "@core/app/shared/components/table-filters/table-filters.component";
import { TableSelectsComponent } from "@core/app/shared/components/table-selects/table-selects.component";
import { TableComponent } from "@core/app/shared/components/table/table.component";
import { SliderModule } from "@core/app/slider/slider.module";
import { SlugifyPipe } from "@core/app/slugify.pipe";
import { SocialLinksComponent } from "@core/app/social-links/social-links.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
	NgbAccordionModule,
	NgbDatepickerModule,
	NgbDropdownModule,
	NgbModalModule,
	NgbNavModule,
	NgbProgressbarModule,
	NgbTimepickerModule,
	NgbTooltipModule,
	NgbTypeaheadModule,
} from "@ng-bootstrap/ng-bootstrap";
import { ModalModule, SmartCrumbsModule } from "shared";
import { DropzoneComponent } from "../dropzone/dropzone.component";
import { FormModule } from "../form/form.module";
import { UtilModule } from "../util/util.module";

@NgModule({
	imports: [
		CommonModule,
		FontAwesomeModule,
		FormsModule,
		NgbModalModule,
		NgbProgressbarModule,
		NgbTypeaheadModule,
		NgbDropdownModule,
		NgbTooltipModule,
		NgbDatepickerModule,
		NgbNavModule,
		NgbTimepickerModule,
		NgbAccordionModule,
		RouterModule,
		LazyModule,
		RouterModule,
		ImageModule,
		ButtonModule,
		Map2Module,
		InventoryModule,
		SearchModule,
		SliderModule,
		LayoutModule,
		Pagination2Module,
		ButtonModule,
		UtilModule,
		FormModule,
		ModalModule,
		SmartCrumbsModule,
	],
	declarations: [
		AttrDirective,
		AutocompleteComponent,
		ClickCopyDirective,
		GroupByPipe,
		SlugifyPipe,
		SocialLinksComponent,
		TableComponent,
		TableFiltersComponent,
		TableSelectsComponent,
		SocialLinks2Component,
		CampaignPickerComponent,
		FormTransparentComponent,
		ArrowDivComponent,
	],
	exports: [
		AttrDirective,
		AutocompleteComponent,
		ClickCopyDirective,
		CommonModule,
		FontAwesomeModule,
		FormsModule,
		GroupByPipe,
		NgbModalModule,
		NgbProgressbarModule,
		NgbTypeaheadModule,
		NgbDropdownModule,
		NgbTooltipModule,
		NgbDatepickerModule,
		NgbNavModule,
		NgbTimepickerModule,
		NgbAccordionModule,
		RouterModule,
		SlugifyPipe,
		SocialLinksComponent,
		DropzoneComponent,
		LazyModule,
		TableComponent,
		TableFiltersComponent,
		TableSelectsComponent,
		ImageModule,
		SocialLinks2Component,
		Map2Module,
		InventoryModule,
		SearchModule,
		SliderModule,
		LayoutModule,
		CampaignPickerComponent,
		FormTransparentComponent,
		ArrowDivComponent,
		Pagination2Module,
		ButtonModule,
		FormModule,
		ModalModule,
		UtilModule,
		SmartCrumbsModule,
	],
	providers: [Location],
})
export class SharedModule {}
