import { Component, Input, Output, EventEmitter, ViewChild, SimpleChanges, OnChanges } from "@angular/core";
import { Observable, of } from "rxjs";
import { debounceTime, tap, switchMap, distinctUntilChanged } from "rxjs/operators";
import { faSpinner, IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: "cm-search",
	template: `
		<div class="search input-group mt-2 mb-2">
			<input
				class="form-control"
				type="text"
				autocomplete="xyz"
				id="xyz"
				name="xyz"
				placeholder="{{ placeholder }}"
				[(ngModel)]="searchString"
				[ngbTypeahead]="onSearch"
				[focusFirst]="focusFirst"
				[resultTemplate]="rt"
				(keyup.enter)="search.emit(searchString)"
				(selectItem)="onSelect($event)"
				[inputFormatter]="formatter"
				(keydown)="typeaheadKeydown($event)"
				#typeaheadInstance="ngbTypeahead"
			/>
			<div class="input-group-append">
				<div
					*ngIf="buttonIcon || buttonText"
					class="btn btn-{{ buttonClass }}"
					(click)="search.emit(searchString)"
				>
					<fa-icon *ngIf="buttonIcon" [icon]="buttonIcon"></fa-icon>
					<span class="text">{{ buttonText }}</span>
				</div>
				<fa-icon *ngIf="searching" class="ml-1 spinner" [icon]="faSpinner" [spin]="true"></fa-icon>
			</div>
		</div>
		<ng-template #rt let-r="result" let-t="term">
			<a routerLink="{{ r.href }}" *ngIf="r.href" tabindex="-1" title="{{ r.label }}">
				<ngb-highlight [result]="r.label" [term]="t"></ngb-highlight>
			</a>
			<span *ngIf="!r.href" tabindex="-1" title="{{ r.label }}">
				<ngb-highlight [result]="r.label" [term]="t"></ngb-highlight>
			</span>
		</ng-template>
	`,
	styles: [
		`
			.spinner {
				font-size: 23px;
				line-height: 1.5;
			}
			.search ::ng-deep ngb-typeahead-window {
				width: 100%;
				max-height: 300px;
				overflow: scroll;
			}
			.search ::ng-deep ngb-typeahead-window button {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 100%;
			}
			.search input {
				min-height: 37px;
			}
		`,
	],
})
export class SearchComponent implements OnChanges {
	@Input() placeholder: string = "Search...";
	@Input() buttonClass: string = "outline-secondary";
	@Input() searchString: string = "";
	@Input() focusFirst: boolean = false;
	@Input() buttonText?: string;
	@Input() buttonIcon?: IconDefinition;
	@Input() buttonClick: SearchButtonClick = () => {
		/** do nothing */
	};
	@Input() searchFn: SearchFunction = () => of([]);
	// TODO: Allow search component to accept template

	@Output() search: EventEmitter<string> = new EventEmitter();
	@Output() result: EventEmitter<any> = new EventEmitter();

	@ViewChild("typeaheadInstance", { static: true }) private typeaheadInstance!: NgbTypeahead;

	onSearch = (text$: Observable<string>) => {
		return text$.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			tap(() => (this.searching = true)),
			switchMap((text) => this.searchFn(text)),
			tap(() => (this.searching = false)),
		);
	};

	onSelect($event: any) {
		$event.preventDefault();
		this.searchString = "";
		this.result.emit($event);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.searchString && changes.searchString.currentValue) {
			this.searchString = changes.searchString.currentValue;
		}
	}

	typeaheadKeydown($event: KeyboardEvent) {
		if (this.typeaheadInstance.isPopupOpen()) {
			setTimeout(() => {
				const popup = document.getElementById(this.typeaheadInstance.popupId);
				if (popup) {
					const activeElements = popup.getElementsByClassName("active");
					if (activeElements.length === 1) {
						const elem = activeElements[0] as any;
						if (typeof elem.scrollIntoViewIfNeeded === "function") {
							elem.scrollIntoViewIfNeeded();
						} else {
							this.scrollIntoViewIfNeededPolyfill(elem as HTMLElement);
						}
					}
				}
			});
		}
	}

	formatter = (x: { label: string }) => x.label;

	faSpinner = faSpinner;
	searching: boolean = false;

	private scrollIntoViewIfNeededPolyfill(elem: HTMLElement, centerIfNeeded = true) {
		const parent = elem.parentElement;
		const parentComputedStyle = window.getComputedStyle(parent!, null);
		const parentBorderTopWidth = parseInt(parentComputedStyle.getPropertyValue("border-top-width"));
		const parentBorderLeftWidth = parseInt(parentComputedStyle.getPropertyValue("border-left-width"));
		const overTop = elem.offsetTop - parent!.offsetTop < parent!.scrollTop;
		const overBottom =
			elem.offsetTop - parent!.offsetTop + elem.clientHeight - parentBorderTopWidth >
			parent!.scrollTop + parent!.clientHeight;
		const overLeft = elem.offsetLeft - parent!.offsetLeft < parent!.scrollLeft;
		const overRight =
			elem.offsetLeft - parent!.offsetLeft + elem.clientWidth - parentBorderLeftWidth >
			parent!.scrollLeft + parent!.clientWidth;
		const alignWithTop = overTop && !overBottom;

		if ((overTop || overBottom) && centerIfNeeded) {
			parent!.scrollTop =
				elem.offsetTop -
				parent!.offsetTop -
				parent!.clientHeight / 2 -
				parentBorderTopWidth +
				elem.clientHeight / 2;
		}

		if ((overLeft || overRight) && centerIfNeeded) {
			parent!.scrollLeft =
				elem.offsetLeft -
				parent!.offsetLeft -
				parent!.clientWidth / 2 -
				parentBorderLeftWidth +
				elem.clientWidth / 2;
		}

		if ((overTop || overBottom || overLeft || overRight) && !centerIfNeeded) {
			elem.scrollIntoView(alignWithTop);
		}
	}
}

export type SearchButtonClick = (searchString: string | null) => void;
export type SearchFunction = (text: string) => Observable<any[]>;
