import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SliderModule } from "../slider/slider.module";
import { InstagramSlideshowComponent } from "./components/instagram-slideshow/instagram-slideshow.component";

@NgModule({
	declarations: [InstagramSlideshowComponent],
	imports: [CommonModule, SliderModule],
	exports: [InstagramSlideshowComponent],
})
export class InstagramModule {}
