import { Component, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "@core/app/user.service";
import { faSmile } from "@fortawesome/pro-solid-svg-icons";
import { IPageData } from "@model/page-data";

@Component({
	selector: "cm-footer-athome",
	template: `
		<div class="container-fluid" itemscope itemtype="http://schema.org/WPFooter">
			<meta itemprop="copyrightYear" [content]="year" />
			<meta itemprop="copyrightHolder" [content]="siteName" />
			<div class="row">
				<div class="col-12 col-lg-4 d-flex justify-content-center justify-content-lg-start">
					<cm-locations></cm-locations>
				</div>
				<div class="col-12 col-lg-4 my-4 my-lg-0 d-flex flex-column text-center">
					<a routerLink="/" class="d-block my-2 home-link">
						<div class="flex-grow-1">
							<span class="text-athome-green"><fa-icon [icon]="faSmile"></fa-icon><b>Home</b>Realty</span>
						</div>
					</a>
					<cm-social-links2></cm-social-links2>
				</div>
				<div class="col-12 col-lg-4 text-center text-lg-right d-flex flex-column">
					<cm-trust-icons class="mb-1"></cm-trust-icons>
					<div class="flex-grow-1 mr-1">
						<div class="mb-1 text-muted">
							Real Estate Website Design & Internet Marketing by
							<a href="https://designforcemarketing.com/">Design Force</a>.
						</div>
						<div class="mb-1 text-muted">
							&copy; Copyright {{ year }} {{ siteName }}. All Rights Reserved.
						</div>
						<div>
							<a [routerLink]="'/terms-of-use'">Terms of Use</a> |
							<a [routerLink]="'/privacy-policy'">Privacy Policy</a>
						</div>
						<div *ngIf="!(user.user$ | async)">
							<a href="javascript:void(0)" (click)="showLogin = true">Admin Login</a>
						</div>
						<div *ngIf="user.user$ | async">
							<a href="javascript:void(0)" (click)="user.logOut(); reload()">Logout</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<cm-login-modal [show]="showLogin" (showChange)="showLogin = $event"></cm-login-modal>
	`,
	styleUrls: ["./footer-athome.component.scss"],
})
export class FooterAthomeComponent {
	year = new Date().getFullYear();
	faSmile = faSmile;
	siteName = this.pageData.headerData.queries.GetDealerInfo[0].dealer;
	showLogin = false;
	constructor(@Inject("PAGE_DATA") private pageData: IPageData, public user: UserService, public router: Router) {}

	reload() {
		const currentUrl = this.router.url;
		this.router.navigate([currentUrl]);
	}
}
