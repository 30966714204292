import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { differenceInSeconds } from "date-fns-2";
import { fromEvent, Subscription } from "rxjs";
import { isPrerendering } from "shared";
import { CookieService } from "./cookie.service";
import { GTMService } from "./gtm.service";
import { RestService } from "./rest.service";
import { UserService } from "./user.service";

let LAST_TRACK: any = null;
let TRACK_POLL_TIMEOUT: any = null;

@Injectable({ providedIn: "root" })
export class TrackingService {
	onWindowScroll: Subscription | null = null;
	onDocumentMouseLeave: Subscription | null = null;
	service: any = null;

	constructor(
		private GTMService: GTMService,
		private CookieService: CookieService,
		@Inject(DOCUMENT) private document: any,
		private route: ActivatedRoute,
		private UserService: UserService,
		private RestService: RestService,
	) {
		this.service = this.RestService.init("visitor");

		window.onbeforeunload = () => this.trackVisit({ leave: true });
	}

	/**
	 * Track route changes in the app
	 *
	 * @param route
	 */
	trackRouteChange(prevUrl: string | null, nextUrl: string) {
		const input = { currentUrl: { url: nextUrl }, lastUrl: { url: prevUrl } };

		// track the visit to this page
		this.trackVisit(input);

		// let google analytics know the url has changed
		this.GTMService.trackEvent("ngRouteChange", {
			route: window.location.href.replace(window.location.origin, ""),
		});

		const trackVisitRateLimited = () => this.trackVisitRateLimited(input);

		// TODO: this should be 0.5, 1, 2.5, 5, 10, and 15 minutes
		// track again after 5, 10, and 15 minutes
		this.timeoutSequenceCancel();
		if (!isPrerendering())
			this.timeoutSequence([1000 * 60 * 5, 1000 * 60 * 5, 1000 * 60 * 5], trackVisitRateLimited);

		// track mouseleave and scroll up
		if (this.onWindowScroll) {
			this.onWindowScroll.unsubscribe();
			this.onWindowScroll = null;
			this.onDocumentMouseLeave!.unsubscribe();
			this.onDocumentMouseLeave = null;
		}
		LAST_TRACK = new Date();
		this.onDocumentMouseLeave = fromEvent(this.document, "mouseleave").subscribe(trackVisitRateLimited);
		this.onWindowScroll = fromEvent(window, "scroll").subscribe(trackVisitRateLimited);
	}

	/**
	 * Tracks a visit to the page
	 *
	 * @param input
	 * @returns {Promise<void>}
	 */
	async trackVisit(input: any) {
		// if (isScullyRunning()) {
		// 	return;
		// }
		// if (this.UserService.loggedIn() && !this.UserService.isPublic()) {
		// 	if (this.onWindowScroll) {
		// 		this.onWindowScroll.unsubscribe();
		// 		this.onWindowScroll = null;
		// 		this.onDocumentMouseLeave!.unsubscribe();
		// 		this.onDocumentMouseLeave = null;
		// 	}
		// 	this.timeoutSequenceCancel();
		// 	return;
		// }
		// if (input !== undefined && !input.hasOwnProperty("currentUrl")) {
		// 	input.currentUrl = { url: window.location.pathname };
		// }
		// if (
		// 	input !== undefined &&
		// 	input.hasOwnProperty("currentUrl") &&
		// 	input.currentUrl.url !== "/oops" &&
		// 	input.currentUrl.url.indexOf("admin") === -1
		// ) {
		// 	const searchQuery = input.searchQuery || null;
		// 	const leave = input.leave || false;
		// 	let email;
		// 	let utm_campaign;
		// 	let utm_source;
		// 	let utm_medium;
		// 	let utm_term;
		// 	this.route.queryParams
		// 		.pipe(first())
		// 		.subscribe((params) => {
		// 			email = params.email || null;
		// 			utm_campaign = params.utm_campaign || null;
		// 			utm_source = params.utm_source || null;
		// 			utm_medium = params.utm_medium || null;
		// 			utm_term = params.utm_term || null;
		// 		})
		// 		.unsubscribe();
		// 	const response = await this.service.post("track", {
		// 		host: window.location.host,
		// 		hostname: window.location.hostname,
		// 		protocol: window.location.protocol,
		// 		port: window.location.port,
		// 		actionName: input.actionName || null,
		// 		actionId: input.actionId || null,
		// 		searchQuery: searchQuery,
		// 		ts: Math.floor(Date.now() / 1000),
		// 		leave: leave,
		// 		currentUrl: input.currentUrl || { url: window.location.pathname },
		// 		lastUrl: input.lastUrl || null,
		// 		email: email,
		// 		utm_campaign: utm_campaign,
		// 		utm_source: utm_source,
		// 		utm_medium: utm_medium,
		// 		utm_term: utm_term,
		// 		referer: null,
		// 	});
		// 	if (response.success == true && leave != true && response.visitorId != 0) {
		// 		this.CookieService.set("visitorId", response.visitorId, 365 * 30);
		// 		this.CookieService.set("ga_vid", response.visitorId, 365 * 30);
		// 		this.CookieService.set("uid", response.visitorId, 365 * 30);
		// 		if (!this.CookieService.has("visitorVisitId")) {
		// 			// Set 30 minutes cookie
		// 			this.CookieService.set("visitorVisitId", response.visitorVisitId, 1 / 48);
		// 		} else {
		// 			// Refresh
		// 			this.CookieService.set("visitorVisitId", this.CookieService.get("visitorVisitId"), 1 / 48);
		// 		}
		// 	}
		// }
	}

	/**
	 * @param input
	 * @returns {Promise<void>}
	 * @private
	 */
	private async trackVisitRateLimited(input: any) {
		const now = new Date();

		// rate limit to one request every 60 seconds
		if (differenceInSeconds(now, LAST_TRACK) < 60) {
			return;
		}

		LAST_TRACK = now;
		if (this.document.visibilityState === "visible") {
			await this.trackVisit(input);
		}
	}

	// TODO: move into new service
	/**
	 * @param sequence Array of delays in milliseconds. For performance reasons, the array is processed in reverse order.
	 * @param callback
	 * @private
	 */
	private timeoutSequence(sequence: any, callback: any) {
		if (sequence.length === 0) {
			return;
		}

		const delay = sequence.pop();

		TRACK_POLL_TIMEOUT = setTimeout(() => {
			callback();
			this.timeoutSequence(sequence, callback);
		}, delay);
	}

	// TODO: move into new service
	/**
	 * @private
	 */
	private timeoutSequenceCancel() {
		if (TRACK_POLL_TIMEOUT) {
			clearTimeout(TRACK_POLL_TIMEOUT);
			TRACK_POLL_TIMEOUT = null;
		}
	}
}
