/// <reference types="googlemaps" />

export function mapUrl(address: any): string | null {
	let url = null;
	if (address) {
		let param: string = address.address1!;
		if (address.address2) {
			param += ` ${address.address2}`;
		}
		param += ` ${address.city.city}, ${address.city.stateprov.abbrev} ${address.postal_code}`;
		url = `https://www.google.com/maps/dir//${param.replace(/\s/g, "+")}`;
	}
	return url;
}

export function expandBoundsMiles(bounds: google.maps.LatLngBoundsLiteral, miles: number) {
	const withinLat = miles / 69.172;
	bounds.south -= withinLat;
	bounds.north += withinLat;

	const midLat = (bounds.north - bounds.south) / 2 + bounds.south;
	const lngMiles = Math.cos((midLat * Math.PI) / 180) * 69.172;
	const withinLng = miles / lngMiles;
	bounds.west -= withinLng;
	bounds.east += withinLng;

	return bounds;
}
