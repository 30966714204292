import { IEnvironmentPartial } from "./environment-types";

export const environment: IEnvironmentPartial = {
	dealerInfo: {
		name: "athome",
		homeBreadcrumb: "HomeRealty",
		themeData: {
			dealerAccent: null,
		},
	},
	font: {
		family: "GillSans",
	},
};
