import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgbButtonsModule } from "@ng-bootstrap/ng-bootstrap";
import { ModalModule } from "shared";
import { SharedModule } from "../shared/shared.module";
import { AccountComponent } from "./components/account.component";
import { LoginModalComponent } from "./components/login-modal.component";

@NgModule({
	declarations: [AccountComponent, LoginModalComponent],
	imports: [CommonModule, ModalModule, NgbButtonsModule, FormsModule, SharedModule],
	exports: [LoginModalComponent],
})
export class AccountModule {}
