import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SMap } from "@common/map2";
import { TransferRxService } from "@common/services/transfer-rx.service";
import { Observable, of } from "rxjs";
import { concat, map, shareReplay } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class Menu2Service {
	private menus = new SMap<number, Observable<IMenuItem[] | null>>();

	constructor(private transfer: TransferRxService, private http: HttpClient) {}

	/**
	 * Returned observable emits null while loading
	 */
	getMenu(appMenuid: number): Observable<IMenuItem[] | null> {
		return this.menus.getOrInsertWith(appMenuid, () => {
			const items$ = this.transfer
				.transfer$(`GetAppMenu:${appMenuid}`, () =>
					this.http.post("/api/statement/GetAppMenu", { vars: { app_menuid: appMenuid } }),
				)
				.pipe(
					map((res: any) => this.makeMenuTree(res.results)),
					shareReplay(),
				);

			return of(null).pipe(concat(items$));
		});
	}

	private makeMenuTree(flatItems: any[]): IMenuItem[] {
		const items = [];
		const itemsById = new Map<string, IMenuItem>();

		for (const row of flatItems) {
			const item = { text: row.app_menu_item, url: row.url, children: [] };

			if (row.app_menu_item_ofid) {
				// items are guaranteed to be sorted so that all items are after their parent
				itemsById.get(row.app_menu_item_ofid)!.children.push(item);
			} else {
				// only add the item to the root list if it doesn't have a parent
				items.push(item);
			}

			itemsById.set(row.app_menu_itemid, item);
		}

		return items;
	}
}

export interface IMenuItem {
	text: string;
	url: string | null;
	children: IMenuItem[];
}
