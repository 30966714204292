import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { ReplaySubject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "cm-smart-crumbs",
	template: `<ol
		class="d-print-none list-inline py-1"
		itemscope
		itemtype="http://schema.org/BreadcrumbList"
		*ngIf="crumbs && crumbs.length > 0"
	>
		<li
			class="pull-left"
			*ngFor="let crumb of crumbs; let index = index; let last = last"
			itemprop="itemListElement"
			itemscope
			itemtype="http://schema.org/ListItem"
		>
			<a itemprop="item" class="crumb-link-wrap text-primary" routerLink="{{ crumb.content_page_url }}"
				><span itemprop="name">{{ crumb.breadcrumb || crumb.h1 }}</span></a
			><meta itemprop="position" content="{{ index + 1 }}" /><fa-icon
				*ngIf="!last"
				class="mx-1 text-primary"
				[icon]="faChevronRight"
			></fa-icon>
		</li>
	</ol>`,
})
export class SmartCrumbsComponent implements OnDestroy {
	possibleUrls: string[] = [];
	crumbs: any[] = [];
	faChevronRight = faChevronRight;

	private ngOnDestroyRS: ReplaySubject<void> = new ReplaySubject();

	constructor(route: ActivatedRoute, private http: HttpClient, private cd: ChangeDetectorRef) {
		route.data.pipe(takeUntil(this.ngOnDestroyRS)).subscribe((data) => {
			const metaInfo =
				data.routeData.stmt.success && data.routeData.stmt.results && data.routeData.stmt.results[0]
					? data.routeData.stmt.results[0]
					: null;
			if (metaInfo !== null) {
				this.processCrumbs(metaInfo.content_page_url);
			}
		});
	}

	processCrumbs(url: string) {
		const temp = url.split("/");
		temp.pop();
		let newUrl = temp.join("/");
		newUrl = newUrl.trim();
		if (newUrl === "") {
			this.possibleUrls.push("/");
			this.http
				.post("/api/statement/GetBreadcrumbs", { vars: { content_page_url: this.possibleUrls } })
				.subscribe((response: any) => {
					this.crumbs = response.results;
					this.cd.markForCheck();
				});
		} else {
			this.possibleUrls.push(newUrl);
			this.processCrumbs(newUrl);
		}
	}

	ngOnDestroy() {
		this.ngOnDestroyRS.next();
		this.ngOnDestroyRS.complete();
	}
}
