import { Component, OnInit } from "@angular/core";
import { RestService, Service } from "@core/app/rest.service";
import { IStmtResults } from "@model/stmt-results";
import { IGetImg } from "@model/stmt/GetImg";
import { first, tap } from "rxjs/operators";

@Component({
	selector: "cm-trust-icons",
	template: `
		<cm-image2
			*ngFor="let icon of icons"
			[image]="{ src: icon.img_dir + icon.img_file | image: 'i', alt: icon.img_alt }"
			class="mx-1"
		></cm-image2>
	`,
	styles: [
		`
			:host {
				display: block;
			}
			cm-image2 {
				max-width: 50px;
				display: inline-block;
			}
		`,
	],
})
export class TrustIconsComponent implements OnInit {
	icons: any[] = [];
	statementService: Service;
	constructor(restService: RestService) {
		this.statementService = restService.init("statement");
	}

	ngOnInit() {
		this.statementService
			.post$("GetImg", { vars: { img_cat: "TrustIcon", limit: 3 } })
			.pipe(
				tap((response: IStmtResults<IGetImg>) => (this.icons = response.results!)),
				first(),
			)
			.subscribe();
	}
}
